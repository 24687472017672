<template>
  <a-spin :spinning="ifSub">
    <a-form layout="horizontal" :form="form">
      <a-form-item label="职位名称"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-input v-decorator="['name',
                {rules:[
                  {type:'string',required:true,message:'职位名称不能为空'},
                  {type:'string',max:30,message:'职位名称不能超过30个字符'},
                  ],trigger:'change'}
                ]"
                 placeholder="输入职位名称"></a-input>
      </a-form-item>
      <a-form-item label="设置排序号"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-input-number :step="0.1"
                        style="width: 100%"
                        v-decorator="['sorter', {rules:[
          {type:'number',required:true,message:'排序号必须为数字'}],trigger:'change',initialValue:0}]"
                        placeholder="输入排序号">
        </a-input-number>
        <span style="color: #808695;font-size: 10px;line-height: 10px">显示顺序，升序排列</span>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '../../../api/service.url'

  const formItemVO = () => {
    return {
      id: '',
      name: '',
      sorter: '',
      creater: '',
      dateCreated: '',
      lastUpdated: ''
    }
  }

  export default {
    name: 'JobForm',
    props: {
      type: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        ifSub: false,
        form: this.$form.createForm(this),
        formItem: formItemVO(),
        labelCol: { span: 5 },
        wrapperCol: { span: 18 }
      }
    },
    methods: {
      loadData(id) {
        //清空表单数据
        this.formItem = formItemVO()
        this.$http(this, {
          url: SERVICE_URLS.earlBase.job.view,
          params: { id },
          noTips: true,
          success: (data) => {
            this.$nextTick(() => {
              this.setFields(data.body)
            })
          }
        })
      },
      setFields(values) {
        Object.assign(this.formItem, values)
        this.form.setFieldsValue({
          name: this.formItem.name,
          sorter: this.formItem.sorter
        })
      },
      loadSelect() {
        this.$http(this, {
          url: SERVICE_URLS.earlBase.job.select,
          noTips: true,
          success: (data) => {
            this.options = data.body
          }
        })
      },
      setVOFields(values) {
        this.formItem.name = values.name
        this.formItem.sorter = values.sorter
      },
      submitAdd() {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.earlBase.job.create,
              data: this.formItem,
              success: (data) => {
                //添加成功，清空表单数据，并触发回调
                this.form.resetFields()
                //清空表单数据
                this.formItem = formItemVO()
                this.$emit('addSuccess', data.body)
              },
              error: () => {
                this.$emit('addError')
              }
            })
          }
        })
      },
      submitEdit() {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.earlBase.job.update,
              data: this.formItem,
              params: {
                id: this.formItem.id
              },
              success: (data) => {
                //清空表单数据
                this.formItem = formItemVO()
                this.$emit('editSuccess', data.body)
              }
            })
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>