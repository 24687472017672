<template>
  <page-layout>
    <div slot="headerContent">
      <h1 class="title">职位管理</h1>
    </div>
    <div slot="extra">
      <div class="more-info">
        <a-button type="primary" @click="handleAdd">添加职位</a-button>
      </div>
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <!--清单名称-->
            <a-form-item label="职务名称">
              <a-input v-model="searchParams.name" placeholder="请输入职务名称"/>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" :loading="tableLoading" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table :columns="columns"
               :rowKey="record => record.id"
               :dataSource="tableData"
               :pagination="pagination"
               :loading="tableLoading"
               :scroll="{x:1000}"
               @change="tableChange">
        <template slot="_index" slot-scope="text, record, index">{{searchParams.number*searchParams.size+index+1}}
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button @click="handleEdit(record.id)"
                    shape="circle"
                    icon="edit"
                    size="small"></a-button>
          <a-divider type="vertical"/>
          <a-button type="danger"
                    @click="handleDelete(record.id)"
                    shape="circle"
                    icon="delete"
                    size="small"></a-button>
        </template>
      </a-table>
    </a-card>
    <a-modal title="职务添加"
             :visible="addModal"
             :maskClosable="false"
             @ok="handleAddOk"
             @cancel="closeAddModal">
      <job-form ref="addRef" type="add"
                @addSuccess="addSuccess"
                @addError="addError">
      </job-form>
    </a-modal>
    <a-modal title="职务修改"
             :visible="editModal"
             :maskClosable="false"
             @ok="handleEditOk"
             @cancel="closeEditModal">
      <job-form ref="editRef" type="edit"
                @editSuccess="editSuccess"
                @editError="editError">
      </job-form>
    </a-modal>
  </page-layout>
</template>

<script>
  import entityCRUD from '@/views/common/mixins/entityCRUD'
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import JobForm from './JobForm'

  export default {
    name: 'Job',
    mixins: [entityCRUD],
    components: { PageLayout, JobForm },
    data() {
      return {
        entityBaseUrl: SERVICE_URLS.earlBase.job,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          name: ''
        },
        initColumns: [
          {
            title: '#',
            width: 30,
            align: 'center',
            scopedSlots: { customRender: '_index' }
          },
          {
            title: '名称',
            align: 'center',
            dataIndex: 'name'
          },
          {
            title: '创建时间',
            dataIndex: 'dateCreated',
            align: 'center',
            key: 'dateCreated',
            width: 300
          },
          {
            title: '操作',
            align: 'center',
            width: 200,
            fixed: 'right',
            scopedSlots: { customRender: 'action' }
          }
        ]
      }
    },
    methods: {
      handleDelete(id) {
        const _this = this
        this.$confirm({
          title: '确认删除操作?',
          content: '请确保该职务未被引用，否则将删除失败。',
          okText: '确认',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            _this.$http(_this, {
              url: _this.deleteUrl,
              params: { id },
              noTips: false,
              success: () => {
                _this.search()
              }
            })
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>